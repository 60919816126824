@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($color: $black, $alpha: 0.8);
  transform: translateZ(0);
  z-index: 1;
  animation: show 0.3s;
  transition: all 0.3s;
  display: none;

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.active {
  display: block;
}
