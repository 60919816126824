@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px 0;

  @include vp-1024 {
    padding: 10px 0;
    margin-bottom: 40px;
  }
}

.logo {
  width: 64px;
  height: 83px;
  cursor: pointer;

  @include vp-1024 {
    width: 36px;
    height: 48px;
  }
}

.admin {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}

.avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
}

.name {
  color: $black;
  @include text(18px, 500, 22px);

  @include vp-1024 {
    @include text(16px, 500, 22px);
  }
}

button.logOut {
  @include text(18px, 700, 22px);
  padding: 9px 24px;

  @include vp-1024 {
    @include text(16px, 700, 22px);
    padding: 9px 12px;
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 16px;
}
