@import 'src/styles/mixins';
@import 'src/styles/variables';

.button {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  transition: all 0.3s;
}

.outline {
  padding: 8px;

  background: $white;
  border: 1px solid #979da552;
  border-radius: 100px;

  transition: all 0.3s;

  .svg path {
    transition: all 0.3s;
    stroke: $black;
  }

  &:hover {
    border: 1px solid $primary;

    .svg path {
      stroke: $primary;
    }
  }

  &:active {
    border: 1px solid $primary;
  }
}

.filled {
  padding: 8px;
  background: $primary;
  border-radius: 50px;

  .svg path {
    transition: all 0.3s;
    stroke: $white;
  }
}

.ghost {
  border-radius: 100px;

  transition: all 0.3s;

  .svg path {
    transition: all 0.3s;
    stroke: $black;
  }
}

.small {
  padding: 0;
}

.disabled {
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
}
