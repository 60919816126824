@import 'src/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  max-width: 100%;

  @include vp-435 {
    overflow-x: hidden;
    padding-bottom: 60px;
  }
}

.adaptive {
  max-width: 1368px;
  width: 100%;
  margin: 0 auto;

  @include vp-1439 {
    padding: 0 36px;
  }

  @include vp-1368 {
    margin: 0;
  }

  @include vp-1024 {
    padding: 100px 16px 0;
  }
}

.admin {
  padding: 0 36px;

  @include vp-1024 {
    padding: 0 16px;
  }
}
