@import 'src/styles/variables';
@import 'src/styles/mixins';

.button {
  width: fit-content;
  padding: 8px 16px;
  border-radius: 24px;
  color: $white;
  background-color: $primary;
  transition: all 0.3s;
  @include text(12px, 700, 20px);
  cursor: pointer;
  border: 1px solid $primary;
  font-family: $manrope;
  box-sizing: border-box;
  max-height: 36px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background-color: $white;
    color: $primary;
    border: 1px solid $primary;
  }
}

.outline {
  background-color: $white;
  color: $primary;
  border: 1px solid $primary;

  &:hover {
    color: $white;
    background-color: $primary;
    border: 1px solid $primary;
  }
}

.ghost {
  @include text(14px, 400, 14px);
  color: $primary;
  border: none;
  background-color: transparent;

  &:hover {
    color: $gray;
    background-color: transparent;
    border: none;
  }
}

.gray {
  background-color: $white;
  color: $black;
  border: 1px solid #979da552;

  &:hover {
    background-color: $white;
    color: $primary;
    border: 1px solid $primary;
  }
}

.small {
  padding: 4px 12px;
}

.disabled {
  background-color: $light-gray;
  color: $gray;
  border: 1px solid $light-gray;
  pointer-events: none;
}

.disabled.outline {
  opacity: 1;
  pointer-events: none;
  border: 1px solid $gray;
  color: $gray;
  background-color: transparent;
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.extra {
  @include text(16px, 500, 22px);
  text-align: center;
  padding: 15px;
  width: 100%;
  max-height: 52px;
}
